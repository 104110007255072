import React from 'react';
import './Footer.scss';

function Footer() {
    return (
        <footer className='footer'>
            <p><strong>Fishing Map</strong></p>
            <p>Copyright 2024</p>
        </footer>
    )
}

export default Footer
